.page-container {
  display: flex;
  width: 100vw;
  overflow: hidden;
  height: 90vh;
  position: absolute;
  bottom: 0;
}

.media-container {
  position: relative;
  display: flex;
  width: 100vw;
  transition-duration: 1s;
  justify-content: space-between;
  align-items: center;
}

.probiotics.media-container {
  flex-flow: column;
  height: 90vh;
  justify-content: space-around;
}

.media-container.is-visible .switch-panel,
#central-media-container .switch-panel {
  opacity: 1;
  z-index: 2;
}

.list-container {
  width: 50vw;
}

.probiotics .list-container {
  height: 30vh;
  width: 100vw;
}

.list-container ul {
  list-style: none;
  text-align: start;
  display: flex;
  flex-flow: column;
  width: 85%;
  height: 80vh;
  justify-content: center;
}

.probiotics .list-container ul {
  flex-flow: row;
  width: 100vw;
  justify-content: space-around;
  padding: 0;
  margin: 0;
}
.probiotics li::before {
  content: none;
}

h4 {
  font-size: 3vh;
}

li::before {
  content: "\25A0";
  color: #d52b1e;
  /* background-color: #d52b1e; */
  display: inline-block;
  width: 4vh;
  margin: 1vh 0vw -1vh -4vh;
  font-size: 4vh;
}

li {
  padding: 1vw 3vw;
  font-size: 2.5vh;
  overflow: hidden;
}

.probiotics .list-container ul li {
  width: 30vh;
  padding: 0;
  text-align: center;
}

li.probiotics {
  padding: 0vw 3vw;
}

li.probiotics:before {
  content: "\25A0";
  color: #d52b1e;
  display: inline-block;
  width: 4vh;
  margin: 1vh 0vw -1vh -4vh;
  font-size: 4vh;
  padding: 0;
}

#footnote {
  font-size: 1vw;
  margin-bottom: 3vh;
}

.science-image-container {
  width: 50vw;
  height: 90vh;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.probiotics .science-image-container {
  flex-flow: row;
  width: 100vw;
  height: 20vh;
  justify-content: space-around;
}

.probiotics .science-image-container img {
  height: 100%;
  width: auto;
}

.molecule-label {
  width: 20vw;
  margin-bottom: 10%;
}

.molecule-label h3 {
  border-radius: 5px;
  text-align: center;
  font-weight: 400;
  font-size: 3vh;
  padding: 3%;
  background-color: #61cbc8;
  color: white;
}

#secondary {
  background-image: radial-gradient(
    circle farthest-corner at 75vw 50vh,
    #c3d3e5 1%,
    #7d9aaa 10%,
    #485463 25%,
    #03202f 50%
  );
}

.hero-image-container {
  width: 50vw;
}

.hero-image {
  position: absolute;
  height: 90vh;
  bottom: 0;
  right: 5%;
  z-index: -1;
}

.hide {
  transform: translateX(-100%);
}

.switch-panel {
  transition: all 0.2s;
  opacity: 0;
  background: none;
  overflow: hidden;
  outline: none;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  scale: 75%;
}

.switch-panel img {
  max-width: 10vh;
  max-height: 10vh;
}

.switch-panel.right {
  position: absolute;
  bottom: 5vh;
  right: 5vh;
}

.switch-panel.left {
  position: absolute;
  bottom: 5vh;
  left: 5vh;
}

.gut-diagram {
  width: 50vw;
  position: relative;
}

.science-image {
  height: 60vh;
  position: relative;
  background: transparent;
}
