@keyframes lozengeSlide {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(00%);
  }
}

@keyframes drawerSlide {
  from {
    transform: scaleY(0);
    transform-origin: top;
  }
  to {
    transform: scaleY(1);
  }
}

.app-header hr {
  background-color: #d52b1e;
  border: 0;
  height: 3px;
  margin: 0;
}

.app-header {
  position: absolute;
  height: 10vh;
  background-color: #03202f;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-end;
  font-weight: 400;
  font-size: 1.8vw;
  color: white;
  width: 100vw;
}

.nav-link-container {
  display: flex;
  flex-flow: row;
}

.navigation-link {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  overflow: hidden;
  cursor: pointer;
  height: auto;  
  width: 18vw;
}

.empty {
  cursor: initial;
  width: 15.5vw;
}

a {
  text-decoration: none;
  color: #fff;
}

 a:hover {
  color: #d52b1e;
  transition: all 0.2s;
}

.lozenge {
  transform: translateY(100%);
  margin-top: 2%;
  height: 1vw;
  width: 8vw;
  background-color: #d52b1e;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.selected > .lozenge {
animation: lozengeSlide 0.5s forwards;
}

.navigation-link:hover > .lozenge {
  animation: lozengeSlide 0.5s forwards;
}


.dropdown {
  justify-self: flex-end;
  position: relative;
  display: inline-block;
}

.dropdown img {
  height: 5vh;
  pointer-events: none;
  /* margin-top: -1vh; */
}

.dropdown:hover img, .dropdown.open img {
  border-radius: 50%;
  background-color: #fff;
  transition: all 0.2s;
}

.dropdown-content {
  border: #d52b1e solid 1px;
  margin: 2px;
  display: none;
  position: absolute;
  right: 0;
  background-color:#03202f;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  transition: all 0.5s;
  overflow: hidden;
  pointer-events: inherit;
  font-size: 2vh;
}

.dropdown-content > div{
  border: #d52b1e solid 1px;

}

.dropdown.open .dropdown-content,
.dropdown-content.open {
  animation: drawerSlide 0.5s forwards;
  display: block;
}

