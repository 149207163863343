@keyframes fadeInRight {
  from {
    width: 10%;
  }
  to {
    width: 80%;
  }
}


#central-media-container {
  display: flex;
  width: 100vw;
  align-items: stretch;
  transition-duration: 1s;
}

.title-slide {
  position: fixed;
  top:0;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-flow: column;
  z-index: 2;
  transition: all 0.5s;
}

.title-slide h2 {
width: 66vw;  
text-align: center;
;
}


.title-slide#gut-brain-axis {
  background: linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0.7)), url("https://storage.googleapis.com/purina-virtual-experience/07-promoting-gastrointestinal-health/background-bifidobacterium.jpg");
}

.title-slide#probiotics {
  background: linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0.7)),center /cover url("https://storage.googleapis.com/purina-virtual-experience/07-promoting-gastrointestinal-health/background-probiotics.png");
}

.title-slide#prebiotics {
  background:linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0.7)), center /cover no-repeat url("https://storage.googleapis.com/purina-virtual-experience/07-promoting-gastrointestinal-health/background-prebiotics.jpg");
}

.title-slide#postbiotics {
  background: linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0.7)),url("https://storage.googleapis.com/purina-virtual-experience/07-promoting-gastrointestinal-health/background-postbiotics.jpg");
}

.title-slide#colostrum {
  background: linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0.7)), center /cover no-repeat url("https://storage.googleapis.com/purina-virtual-experience/07-promoting-gastrointestinal-health/Colostrum_Header.jpg");
}
